import { BrowserRouter, Route, Routes } from "react-router-dom";
import "./App.css";
import Footer from "./NavFooter/Footer";
import Navbar from "./NavFooter/Navbar";
import AboutUs from "./Pages/AboutUs/AboutUs";
import ChooseVehicle from "./Pages/BookingPage/ChooseVehicle/ChooseVehicle";
import Contact from "./Pages/BookingPage/Contact/Contact";
import CustomerType from "./Pages/BookingPage/CustomerType/CustomerType";
import RideDetails from "./Pages/BookingPage/RideDetails/RideDetails";
import TotalSummary from "./Pages/BookingPage/TotalSummary/TotalSummary";
import Cars from "./Pages/Cars/Cars";
import ContactUs from "./Pages/ContactUs/ContactUs";
import HomePage from "./Pages/HomePage/HomePage";
import NotFound from "./Pages/NotFound/NotFound";
import OurServices from "./Pages/OurServices/OurServices";
import AOS from "aos";
import Dashboard from "./Pages/Dashboard/Dashboard";
import Summary from "./Pages/Dashboard/Summary/Summary";
import AddCar from "./Pages/Dashboard/AddCar/AddCar";
import CompanyList from "./Pages/Dashboard/CompanyList/CompanyList";
import Car from "./Pages/Dashboard/Cars/Car";
import Bookings from "./Pages/Dashboard/Bookings/Bookings";
import Booking from "./Pages/Dashboard/Bookings/Booking";
import AllCars from "./Pages/Dashboard/Cars/AllCars";
import "aos/dist/aos.css";
import Login from "./Pages/Login/Login";
import Signup from "./Pages/Signup/Signup";
import { DataProvider } from "./contex/contex";
import PrivateRoute from "./privateRoute/privateRoute";
import Emails from "./Pages/Dashboard/Emails/Emails";
import AllDrivers from "./Pages/Dashboard/Drivers/AllDrivers";
import AllUsers from "./Pages/Dashboard/AllUsers/AllUsers";
import Reviews from "./Pages/Dashboard/Reviews/Reviews";
import AddReviews from "./Pages/AddReviews/AddReviews";
import BookingsDriverView from "./Pages/Dashboard/BookingsDriverView/BookingsDriverView";
import BookingDriverView from "./Pages/Dashboard/BookingsDriverView/BookingDriverView";
import BookingQuote from "./Pages/BookingQuote/BookingQuote";
import Profile from "./Pages/Dashboard/Profile/Profile";
import UserDashboard from "./Pages/Dashboard/UserDashboard";
import DriverDashboard from "./Pages/Dashboard/DriverDashboard";
import Test from "./Test";
import CustomerTypeDashboard from "./Pages/BookingPageDashboard/CustomerType/CustomerType";
import RideDetailsDashboard from "./Pages/BookingPageDashboard/RideDetails/RideDetails";
import ChooseVehicleDashboard from "./Pages/BookingPageDashboard/ChooseVehicle/ChooseVehicle";
import ContactDashboard from "./Pages/BookingPageDashboard/Contact/Contact";
import TotalSummaryDashboard from "./Pages/BookingPageDashboard/TotalSummary/TotalSummary";
import ResetPass from "./Pages/ResetPass/ResetPass";
import ResetPassEmail from "./Pages/ResetPass/ResetPassEmail";

AOS.init({ duration: 1000 });

function App() {
	return (
		<div className='App'>
			<DataProvider>
				<BrowserRouter>
					<Navbar />
					<Routes>
						<Route path='*' element={<NotFound />} />
						<Route path='/' element={<HomePage />} />
						<Route path='/ResetPass/:token' element={<ResetPass />} />
						<Route path='/ResetPassEmail' element={<ResetPassEmail />} />
						<Route path='/login' element={<Login />} />
						<Route path='/test' element={<Test />} />
						<Route path='/Signup' element={<Signup />} />
						<Route path='/AboutUs' element={<AboutUs />} />
						<Route path='/ContactUs' element={<ContactUs />} />
						<Route path='/OurServices/:service' element={<OurServices />} />
						<Route path='/Cars/:car' element={<Cars />} />{" "}
						<Route path='/bookingQuote/:id' element={<BookingQuote />} />{" "}
						<Route
							path='/startBooking'
							element={
								<PrivateRoute
									requiredRoles={["User", "Admin", "Developer", "Driver"]}>
									<CustomerType />
								</PrivateRoute>
							}
						/>
						<Route
							path='/booking'
							element={
								<PrivateRoute
									requiredRoles={["User", "Admin", "Developer", "Driver"]}>
									<RideDetails />
								</PrivateRoute>
							}
						/>
						<Route
							path='/choosevehicle'
							element={
								<PrivateRoute
									requiredRoles={["User", "Admin", "Developer", "Driver"]}>
									<ChooseVehicle />
								</PrivateRoute>
							}
						/>
						<Route
							path='/contactdetails'
							element={
								<PrivateRoute
									requiredRoles={["User", "Admin", "Developer", "Driver"]}>
									<Contact />
								</PrivateRoute>
							}
						/>
						<Route
							path='/totalsummary'
							element={
								<PrivateRoute
									requiredRoles={["User", "Admin", "Developer", "Driver"]}>
									<TotalSummary />
								</PrivateRoute>
							}
						/>
						<Route path='/AddReviews' element={<AddReviews />} />
						<Route
							path='/BookingsDriverView/:id'
							element={
								<PrivateRoute requiredRoles={["Driver", "Admin", "Developer"]}>
									<BookingDriverView />
								</PrivateRoute>
							}
						/>
						<Route
							path='/BookingsDriverView'
							element={
								<PrivateRoute requiredRoles={["Driver", "Admin", "Developer"]}>
									<BookingsDriverView />
								</PrivateRoute>
							}
						/>
						<Route
							path='/dashboard'
							element={
								<PrivateRoute requiredRoles={["Admin", "Developer"]}>
									<Dashboard />
								</PrivateRoute>
							}>
							<Route path='/dashboard' element={<Summary />} />
							<Route path='/dashboard/profile' element={<Profile />} />
							<Route path='/dashboard/AllDrivers' element={<AllDrivers />} />
							<Route path='/dashboard/allUsers' element={<AllUsers />} />
							<Route path='/dashboard/Reviews' element={<Reviews />} />
							<Route path='/dashboard/addvehicle' element={<AddCar />} />
							<Route path='/dashboard/emails' element={<Emails />} />
							<Route path='/dashboard/addcompany' element={<CompanyList />} />
							<Route path='/dashboard/vehicles' element={<AllCars />} />
							<Route path='/dashboard/vehicles/:id' element={<Car />} />
							<Route path='/dashboard/bookings' element={<Bookings />} />
							<Route path='/dashboard/bookings/:id' element={<Booking />} />
							<Route
								path='/dashboard/startBooking'
								element={
									<PrivateRoute requiredRoles={["Admin", "Developer"]}>
										<CustomerTypeDashboard />
									</PrivateRoute>
								}
							/>
							<Route
								path='/dashboard/booking'
								element={
									<PrivateRoute requiredRoles={["Admin", "Developer"]}>
										<RideDetailsDashboard />
									</PrivateRoute>
								}
							/>
							<Route
								path='/dashboard/choosevehicle'
								element={
									<PrivateRoute requiredRoles={["Admin", "Developer"]}>
										<ChooseVehicleDashboard />
									</PrivateRoute>
								}
							/>
							<Route
								path='/dashboard/contactdetails'
								element={
									<PrivateRoute requiredRoles={["Admin", "Developer"]}>
										<ContactDashboard />
									</PrivateRoute>
								}
							/>
							<Route
								path='/dashboard/totalsummary'
								element={
									<PrivateRoute requiredRoles={["Admin", "Developer"]}>
										<TotalSummaryDashboard />
									</PrivateRoute>
								}
							/>
						</Route>
						<Route
							path='/userDashboard'
							element={
								<PrivateRoute requiredRoles={["User"]}>
									<UserDashboard />
								</PrivateRoute>
							}>
							<Route path='/userDashboard' element={<Profile />} />
							<Route path='/userDashboard/bookings' element={<Bookings />} />
							<Route path='/userDashboard/bookings/:id' element={<Booking />} />
						</Route>
						<Route
							path='/driverDashboard'
							element={
								<PrivateRoute requiredRoles={["Driver"]}>
									<DriverDashboard />
								</PrivateRoute>
							}>
							<Route path='/driverDashboard' element={<Profile />} />
							<Route
								path='/driverDashboard/bookings'
								element={<BookingsDriverView />}
							/>
							<Route
								path='/driverDashboard/bookings/:id'
								element={<BookingDriverView />}
							/>
						</Route>
					</Routes>
					<Footer />
				</BrowserRouter>
			</DataProvider>
		</div>
	);
}

export default App;
